import React, { useState } from "react";
import {
  Avatar,
  Popper,
  Card,
  CardActionArea,
  CardHeader,
  IconButton,
  useTheme,
} from "@mui/material";

export default React.memo(CardIconButton);

function CardIconButton({
  title,
  subheader,
  icon,
  href,
  avatar,
  imageSrc,
  imagePhoto,
  ...other
}) {
  const theme = useTheme();
  const [anchorEl, anchorElSet] = useState();

  const iconAvatar = avatar || (
    <Avatar
      style={{
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
      }}
    >
      {icon || title[0]}
    </Avatar>
  );

  return (
    <>
      <Popper open={!!anchorEl} anchorEl={anchorEl} placement="top">
        <Card
          raised
          key={title}
          style={{
            flex: "0 1 auto",
            margin: 10,
            display: "flex",
            width: 350,
            maxWidth: "90vw",
          }}
        >
          <CardActionArea component="a" href={href}>
            {imageSrc && (
              <div
                style={{
                  backgroundColor: "#ddd",
                  ...(!imagePhoto && {
                    paddingTop: 20,
                  }),
                }}
              >
                <div
                  style={{
                    height: 200,
                    backgroundImage: [
                      `linear-gradient(
                      to bottom, 
                      rgba(250, 250, 250, 0), 
                      rgba(250, 250, 250, 0) 75%,
                      rgba(250, 250, 250, 1)
                    )`,
                      `url(${imageSrc})`,
                    ].join(","),
                    backgroundPosition: "center top",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: imagePhoto ? "cover" : "contain",
                  }}
                />
              </div>
            )}
            <CardHeader
              avatar={iconAvatar}
              title={title}
              subheader={subheader}
            />
          </CardActionArea>
        </Card>
      </Popper>
      <IconButton
        {...other}
        aria-label={title}
        href={href}
        onMouseMove={(event) => anchorElSet(event.currentTarget)}
        onMouseLeave={(event) => anchorElSet(null)}
        style={{
          padding: icon ? undefined : 0,
          margin: 5,
          ...other.style,
        }}
      >
        {icon || iconAvatar}
      </IconButton>
    </>
  );
}
