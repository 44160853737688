import React from "react";
import { Avatar } from "@mui/material";
import {
  faStrava,
  faGithub,
  faFacebook,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";
import { faEnvelope, faFilePdf } from "@fortawesome/free-solid-svg-icons";

import importFaIcon from "./importFaIcon";

const StravaIcon = importFaIcon(faStrava);
const GitHubIcon = importFaIcon(faGithub);
const FacebookIcon = importFaIcon(faFacebook);
const LinkedinIcon = importFaIcon(faLinkedin);
const EmailIcon = importFaIcon(faEnvelope);
const ResumeIcon = importFaIcon(faFilePdf);

export const photos = [
  {
    title: "Tatarua Range by John Yang",
    src: require("./photos/IMG_1384.jpeg"),
    position: "top center",
  },
  {
    title: "",
    src: require("./photos/DSCF0906.jpeg"),
    position: "center",
  },
  {
    title: "Mt Victoria, Wellington",
    src: require("./photos/DSCF1992.jpeg"),
    position: "bottom center",
  },
  {
    title: "Mt Ruapehu",
    src: require("./photos/IMG_2394.jpeg"),
    position: "bottom center",
  },
  {
    title: "Kepler Track",
    src: require("./photos/IMG_1067.jpeg"),
    position: "center",
  },
  {
    title: "Great Wall",
    src: require("./photos/IMG_0461.jpeg"),
    position: "top center",
  },
  {
    title: "Paekakariki Escarpment",
    src: require("./photos/IMG_0200.jpeg"),
    position: "top center",
  },
  {
    title: "Namtso",
    src: require("./photos/IMG_3095.jpeg"),
    position: "center",
  },
];

export const headerLinks = [
  {
    icon: <GitHubIcon />,
    title: "GitHub Profile",
    href: "https://github.com/layerssss",
    subheader: "Pieces of code I made or contributede to.",
  },
  {
    icon: <LinkedinIcon />,
    title: "LinkedIn Profile",
    href: "https://www.linkedin.com/in/micyin/",
  },
  {
    icon: <EmailIcon />,
    title: "Email",
    href: "mailto:me@micy.in",
  },
  {
    icon: <ResumeIcon />,
    title: "Resume",
    href: "/resume.pdf",
  },
];

export const bodyLinksCollections = [
  {
    title: "Beautiful things and people I worked with:",
    links: [
      {
        title: 'Glory League',
        subheader: 'Automated sports game film for everone',
        href: 'https://gloryleague.basketball/',
        avatar: <Avatar src={require('./data/gloryleagueLogo.png')} />,
        imageSrc: require('./data/gloryleagueImage.png'),
        imagePhoto: true,
      },
      {
        title: 'Ruby NZ',
        subheader: 'Ruby New Zealand is a community of people who are passionate about Ruby and want to help make it more accessible to you in New Zealand.',
        href: 'https://ruby.nz/',
        avatar: <Avatar src={require('./data/rubynzLogo.png')} />,
      },
      {
        title: "Covid NZ Watch",
        subheader: "Watching COVID-19 data in NZ",
        avatar: (
          <Avatar variant="rounded" src={require("./data/cnsLogo.png")} />
        ),
        imageSrc: require("./data/cnsImage.png"),
      },
      {
        title: "Bearhunt.co",
        subheader: "We're going on a bear hunt.",
        avatar: <Avatar variant="rounded" src={require("./data/bhLogo.png")} />,
        imageSrc: require("./data/bhImage.png"),
      },
      {
        title: "Tracks NZ",
        href: "https://apps.apple.com/nz/app/tracks-nz/id1488245855",
        subheader: "Offline TOPO and others in your pocket",
        avatar: (
          <Avatar variant="rounded" src={require("./data/tracksnzLogo.png")} />
        ),
        imageSrc: require("./data/tracksnzImage.png"),
      },
      {
        title: "Race Calendar",
        subheader:
          "Running, Trail, Marathon and Triathlon race events in New Zealand and Australia.",
        avatar: (
          <Avatar
            variant="rounded"
            src={require("./data/racecalendarLogo.png")}
          />
        ),
        imageSrc: require("./data/racecalendarImage.png"),
      },
      {
        title: "Rocket Reading 1..2..3",
        href: "https://www.rocketreading123.com/",
        subheader: "A reading programme with game for children",
        avatar: <Avatar src={require("./data/rrLogo.png")} />,
        imageSrc: require("./data/rrImage.png"),
      },
      {
        title: "大风车",
        subheader: "A fun running club in the windy capital Wellington",
        avatar: <Avatar src={require("./data/galeridersLogo.png")} />,
        imageSrc: require("./data/galeridersImage.png"),
      },
      {
        title: "优建云",
        href: "https://www.ublib.com/",
        subheader: "全过程的工程信息管理平台",
        avatar: (
          <Avatar src={require("./data/fmsLogo.png")} variant="rounded" />
        ),
        imageSrc: require("./data/fmsImage.jpg"),
        imagePhoto: true,
      },
      {
        title: "vWork",
        href: "https://www.vworkapp.com/",
        subheader: "Job scheduling software for businesses on the move.",
        avatar: <Avatar src={require("./data/vworkLogo.png")} />,
        imageSrc: require("./data/vworkImage.png"),
        imagePhoto: true,
      },
      {
        title: "Mighway",
        subheader: "Hire a campervan direcly from owners and save!",
        avatar: <Avatar src={require("./data/mighwayLogo.png")} />,
        imageSrc: require("./data/mighwayImage.jpg"),
        imagePhoto: true,
      },
      {
        title: "Keepsite",
        subheader: "Construction software designed with project managers.",
        avatar: (
          <Avatar src={require("./data/keepsiteLogo.png")} variant="rounded" />
        ),
        imageSrc: require("./data/keepsiteImage.png"),
      },
      {
        title: "Boost",
        href: "https://www.boost.co.nz/",
        subheader: "Boost, working with you to make a bigger impact.",
        avatar: (
          <Avatar src={require("./data/boostLogo.png")} variant="rounded" />
        ),
        imageSrc: require("./data/boostImage.png"),
        imagePhoto: true,
      },
      {
        title: "小象网",
        subheader:
          "简约文艺个性印品、宝莱LOMO卡、明信片、台历、相册、挂画在线印刷制作平台",
        avatar: <Avatar src={require("./data/puffantLogo.png")} />,
        imageSrc: require("./data/puffantImage.jpeg"),
        imagePhoto: true,
      },
      {
        title: "纸悦",
        subheader: "高端婚礼相册定制",
        avatar: (
          <Avatar
            src={require("./data/merrypaperyLogo.png")}
            variant="rounded"
          />
        ),
        imageSrc: require("./data/merrypaperyImage.jpg"),
        imagePhoto: true,
      },
      {
        title: "盘石软件",
        subheader: "司法取证软件与服务",
        avatar: (
          <Avatar src={require("./data/pansafeLogo.png")} variant="rounded" />
        ),
        imageSrc: require("./data/pansafeImage.jpg"),
        imagePhoto: true,
      },
    ],
  },
];
