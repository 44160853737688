import React from "react";
import { CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material";

import "./App.scss";
import theme from "./theme.js";
import MainPage from "./MainPage";

export default React.memo(App);
function App({ children }) {
  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <MainPage />
      </ThemeProvider>
    </>
  );
}
