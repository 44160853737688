import React, { useEffect, useState, useMemo, useRef } from "react";
import { Typography, IconButton, Paper, Avatar } from "@mui/material";
import { ChevronRight, ChevronLeft } from "mdi-material-ui";

import avatarImage from "./avatar.jpg";
import CardIconButton from "./CardIconButton";
import { photos, headerLinks, bodyLinksCollections } from "./data";

export default React.memo(MainPage);
const PHOTO_TIME = 10000;
function MainPage() {
  const [currentPhotoIndex, currentPhotoIndexSet] = useState(0);
  const currentPhoto = photos[currentPhotoIndex];
  const timerRef = useRef();
  useEffect(
    () => {
      timerRef.current = setTimeout(nextPhoto, PHOTO_TIME);
      return () => {
        clearTimeout(timerRef.current);
      };
    },
    // eslint-disable-next-line
    []
  );
  const prevPhoto = () => {
    clearTimeout(timerRef.current);
    currentPhotoIndexSet(
      (currentPhotoIndex) =>
        (currentPhotoIndex - 1 + photos.length) % photos.length
    );
    timerRef.current = setTimeout(nextPhoto, PHOTO_TIME);
  };
  const nextPhoto = () => {
    clearTimeout(timerRef.current);
    currentPhotoIndexSet(
      (currentPhotoIndex) => (currentPhotoIndex + 1) % photos.length
    );
    timerRef.current = setTimeout(nextPhoto, PHOTO_TIME);
  };

  const backdropStyle = useMemo(() => {
    const backdropFilter = "brightness(1.5) contrast(80%) blur(5px)";
    const supportBackdropFilter = window.CSS?.supports(
      "backdrop-filter",
      backdropFilter
    );

    return supportBackdropFilter
      ? {
          backdropFilter,
          backgroundColor: "rgba(250, 250, 250, 0.2)",
        }
      : {
          backgroundColor: "rgba(250, 250, 250, 0.75)",
        };
  }, []);
  return (
    <>
      <div
        style={{
          minHeight: "100vh",
          display: "flex",
          flexFlow: "column nowrap",
          transition: "background-image ease .3s",
          backgroundImage: `url(${currentPhoto.src})`,
          backgroundSize: "cover",
          backgroundPosition: currentPhoto.position,
          alignItems: "flex-start",
          justifyContent: "flex-end",
          padding: "min(50px, 5vw)",
          paddingBottom: 50,
          position: "relative",
        }}
      >
        <Paper
          elevation={20}
          style={{
            boxSizing: "content-box",
            borderRadius: 20,
            flex: "0 0 auto",
            display: "flex",
            flexFlow: "column nowrap",
            padding: 20,
            // color: "white",
            ...backdropStyle,
          }}
        >
          <div
            style={{
              display: "flex",
              flexFlow: "row wrap",
              alignItems: "center",
            }}
          >
            <Avatar src={avatarImage} />
            <div>
              <h1
                style={{
                  fontSize: "1.2em",
                  fontWeight: "inherit",
                  margin: "0 10px",
                }}
              >
                Michael Yin
              </h1>
              <h2
                style={{
                  fontSize: "1em",
                  fontWeight: "inherit",
                  margin: "0 10px",
                  opacity: 0.8,
                }}
              >
                Web Developer
              </h2>
            </div>
            {headerLinks.map((link) => (
              <CardIconButton key={link.title} {...link} size="small" />
            ))}
          </div>
          {bodyLinksCollections.map((linksCollection) => (
            <React.Fragment key={linksCollection.title}>
              <Typography variant="subtitle1" style={{ paddingTop: 10 }}>
                {linksCollection.title}
              </Typography>
              <div
                style={{
                  display: "flex",
                  flexFlow: "row wrap",
                  maxWidth: 480,
                }}
              >
                {linksCollection.links.map((link) => (
                  <CardIconButton key={link.title} {...link} />
                ))}
              </div>
            </React.Fragment>
          ))}
        </Paper>
        <div
          style={{
            position: "absolute",
            bottom: 5,
            right: 5,
            color: "white",
          }}
        >
          <IconButton color="inherit" onClick={prevPhoto}>
            <ChevronLeft />
          </IconButton>
          <span>{currentPhoto.title || "-"}</span>
          <IconButton color="inherit" onClick={nextPhoto}>
            <ChevronRight />
          </IconButton>
        </div>
      </div>
    </>
  );
}
