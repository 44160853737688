// src/ui/theme/index.js

import { createTheme } from "@mui/material";

const palette = {
  primary: { main: "#6D4C41", contrastText: "#ffffff" },
  secondary: { main: "#546E7A", contrastText: "#000000" },
  background: {
    paper: "#fafafa",
    default: "#546E7A",
  },
};
const themeName = "Kabul Silver Kangaroo Rat";

export default createTheme({ palette, themeName });
